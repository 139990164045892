import { defineStore } from 'pinia'

export const useMenuRefresh = defineStore('counter', {
  state: () => ({ refresh:false }),
  actions: {
    toggleFetch() {
      this.refresh = !this.refresh
    },
  },
})
